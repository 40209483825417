import env from './env';

/*
 *** CLIENT APP CONFIGURATIONS ***
 *
 *  Configurations for the client application.
 */
export default {
  isProduction: env.isProduction,

  sam3: env.isProduction
    ? {
        redirectURL: 'https://comfortlogin.ng.telekom.net/',
        clientID: '10LIVESAM30000004901FTVCOMFORTLOGINWA000',
        loginURL: 'https://accounts.login.idm.telekom.com/oauth2/auth',
        tokenURL: 'https://accounts.login.idm.telekom.com/oauth2/tokens',
        authURL: 'https://accounts.login.idm.telekom.com/oauth2/bc-auth/end',
      }
    : {
        redirectURL: 'https://test.comfortlogin.ng.telekom.net/',
        clientID: '10TVL0SAM30000004901FTVCOMFORTLOGINWA000',
        loginURL:
          'https://login.acceptance.p5x.telekom-dienste.de/oauth2/auth',
        tokenURL:
          'https://login.acceptance.p5x.telekom-dienste.de/oauth2/tokens',
        authURL:
          'https://login.acceptance.p5x.telekom-dienste.de/oauth2/bc-auth/end',
      },
};
